import { render, staticRenderFns } from "./dataTableEvents.vue?vue&type=template&id=18259d05&scoped=true"
import script from "./dataTableEvents.vue?vue&type=script&lang=js"
export * from "./dataTableEvents.vue?vue&type=script&lang=js"
import style0 from "./dataTableEvents.vue?vue&type=style&index=0&id=18259d05&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18259d05",
  null
  
)

export default component.exports